var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticClass: "search_member-popup",
      attrs: { title: "Find & Add Coach" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "space-between", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 17 } },
            [
              _c("a-input", {
                attrs: { type: "email", placeholder: "Enter email" },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 6 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchEmails },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        _vm._l(_vm.members, function (member) {
          return _c(
            "a-row",
            { key: member.user_id, attrs: { type: "flex", gutter: 4 } },
            [
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(member.first_name) +
                    " " +
                    _vm._s(member.last_name) +
                    "..\n      "
                ),
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _vm._v("\n        " + _vm._s(member.work_email) + "\n      "),
              ]),
              _c(
                "a-col",
                { staticClass: "gx-text-right", attrs: { span: 6 } },
                [
                  !member.status
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            block: "",
                            size: "small",
                            loading: _vm.loader && _vm.selectedId === member.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addCoach(member.id)
                            },
                          },
                        },
                        [_vm._v("\n          Add Coach\n        ")]
                      )
                    : _vm._e(),
                  member.status
                    ? _c(
                        "a-button",
                        { attrs: { type: "danger", block: "", size: "small" } },
                        [[_vm._v("Coch Added")]],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm.members.length < 1 && _vm.error_msg
        ? _c("div", { staticClass: "gx-text-center" }, [
            _vm._v("\n    Member not found\n  "),
          ])
        : _vm._e(),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }