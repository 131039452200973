var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderComponent
    ? _c(
        "n-page",
        [
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { staticClass: "gx-text-right", attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.openAdminModal(null)
                            },
                          },
                        },
                        [_vm._v("\n          Create a new Coach\n        ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.openCoachModal },
                        },
                        [_vm._v("Find & Add Coach")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "gx-card-table-full",
                      attrs: { title: "Coaches" },
                    },
                    [
                      _c("club-admins-list-table", {
                        on: { edit: _vm.openAdminModal },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("club-admin-edit-modal", {
            attrs: {
              "admin-id": _vm.currentAdmin,
              visible: _vm.clubAdminModal,
            },
            on: { close: _vm.closeAdminModal },
          }),
          _c("find-coach-modal", {
            attrs: { visible: _vm.findCoachModal },
            on: { close: _vm.closeAdminModal },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }